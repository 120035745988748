import { cva, type VariantProps } from 'class-variance-authority'

const textVariants = cva(null, {
	variants: {
		as: {
			h1: 'text-h1',
			h2: 'text-h2',
			h3: 'text-h3',
			h4: 'text-h4',
			h5: 'text-h5',
			h6: 'text-h6',
			p: 'text-body-md',
			sm: 'text-body-sm',
			xs: 'text-body-xs',
		},
		color: {
			default: 'text-foreground',
			secondary: 'text-secondary',
			destructive: 'text-destructive',
			gradient:
				'bg-gradient-to-b from-primary to-secondary bg-clip-text text-transparent',
			light: 'text-foreground/80', // Lighter text / gray text
		},
	},
	defaultVariants: {
		as: 'p',
		color: 'default',
	},
})

interface TextProps extends VariantProps<typeof textVariants> {
	children: React.ReactNode
	className?: string
	viewTransitionName?: string | undefined
}

export default function Text({
	children,
	className,
	as = 'p',
	color = 'default',
	viewTransitionName,
}: TextProps) {
	const Comp = as == 'xs' || as == 'sm' ? 'p' : as!
	return (
		<Comp
			className={textVariants({ as, color, className })}
			style={
				viewTransitionName
					? { viewTransitionName: viewTransitionName }
					: undefined
			}
		>
			{children}
		</Comp>
	)
}
